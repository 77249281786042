<template>
<div>
    <GeneralSubHeader>
        <template #title>
            Badge Setup
        </template>
        <template #buttons />
    </GeneralSubHeader>

    <div
        :key="`${key}_${reloadKey}`"
        class="kt-container kt-grid__item kt-grid__item--fluid"
    >
        <BadgeTypeDescriptions :badge-type="selectedTab" />
        <div class="row">
            <div class="col-12">
                <div class="kt-portlet kt-portlet--tabs">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <ul class="nav nav-tabs nav-tabs-line nav-tabs-line-brand nav-tabs-line-2x nav-tabs-line-right nav-tabs-bold" role="tablist">
                                <li
                                    v-for="tab in tabs"
                                    :key="tab.name"
                                    class="nav-item"
                                >
                                    <router-link
                                        :to="{
                                            name: 'BadgeSetup',
                                            query: {
                                                badgeType: tab.name
                                            }
                                        }"
                                        :class="tab.name == selectedTab ? 'nav-link active' : 'nav-link'"
                                        role="tab"
                                    >
                                        <i :class="tab.icon" aria-hidden="true" />
                                        {{ tab.name }}s
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                        <div class="kt-portlet__head-toolbar">
                            <div class="kt-portlet__head-group mt-3">
                                <router-link
                                    :to="{
                                        name: 'EditBadge',
                                        query: {
                                            badgeType: selectedTab,
                                        },
                                        params: {
                                            badgeId: 'new'
                                        }
                                    }"
                                    class="btn btn-sm btn-clean"
                                >
                                    <i class="la la-plus" />
                                    Add {{ selectedTab }}
                                </router-link>
                            </div>
                        </div>
                    </div>

                    <div class="kt-portlet__body">
                        <div class="table-responsive pb-3">
                            <div v-if="filteredBadges.length == 0" class="text-center">
                                <a
                                    v-if="isSetupWizard && step.status == 'danger'"
                                    href="#"
                                    class="btn btn-wide btn-upper btn-secondary mt-3 mr-2"
                                    @click.stop.prevent="skipSetupWizard"
                                >
                                    <i class="flaticon-download mr-1" />
                                    Skip this step
                                </a>
                                <a
                                    href="#"
                                    class="btn btn-wide btn-upper btn-success mt-3"
                                    @click.stop.prevent="importDefaultSet"
                                >
                                    <i class="flaticon-download mr-1" />
                                    Import Badge Set
                                </a>
                            </div>
                            <table v-else class="table table-striped">
                                <tbody>
                                    <tr
                                        v-for="(badge, idx) in filteredBadges"
                                        :key="`badge_${badge.badgeId}`"
                                    >
                                        <td class="row_desc ">
                                            <div class="media">
                                                <span
                                                    v-if="badge.badgeIcon"
                                                    style="font-size: 2rem;"
                                                    class="mr-3"
                                                >
                                                    {{ badge.badgeIcon }}
                                                </span>
                                                <div class="media-body align-self-center">
                                                    <router-link
                                                        :to="{
                                                            name: 'EditBadge',
                                                            params: {
                                                                badgeId: badge.badgeId
                                                            },
                                                        }"
                                                        :class="`anchor-link kt-font-bold font ${badge.badgeColor}`"
                                                    >
                                                        <span v-if="badge.badgeType == 'Achievement Level'">{{ idx+1 }}. </span>
                                                        <span>{{ badge.badgeTitle }}</span>
                                                    </router-link>
                                                    <div class="text-muted">
                                                        {{ badge.badgeDescription }}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="row_actions text-center">
                                            <button
                                                :v-b-tooltip.hover="'Delete Badge'"
                                                type="button"
                                                class="btn btn-clean btn-clean-danger btn-icon btn-sm"
                                                @click.stop.prevent="deleteBadge(badge)"
                                            >
                                                <i class="la la-trash" />
                                            </button>

                                            <button
                                                v-if="badge.badgeType !== 'Academic Award'"
                                                :v-b-tooltip.hover="'Move Up'"
                                                type="button"
                                                class="btn btn-clean btn-clean-primary btn-icon btn-sm"
                                                @click.stop.prevent="moveLevelUp(idx)"
                                            >
                                                <i class="la la-angle-up" />
                                            </button>
                                            <button
                                                v-if="badge.badgeType !== 'Academic Award'"
                                                :v-b-tooltip.hover="'Move Down'"
                                                type="button"
                                                class="btn btn-clean btn-clean-primary btn-icon btn-sm"
                                                @click.stop.prevent="moveLevelDown(idx)"
                                            >
                                                <i class="la la-angle-down" />
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <BadgeImportModal ref="badgeImportModal" />
    </div>
</div>
</template>

<script lang="ts">

import Vue from 'vue';

import { mapState } from 'vuex';
import Types from '../store/Types';
import * as network from '../network';
import GeneralSubHeader from '../components/GeneralSubHeader.vue';
import BadgeImportModal from '../components/BadgeImportModal.vue';
import BadgeTypeDescriptions from '../components/BadgeTypeDescriptions.vue';

export default Vue.extend({
    name: 'BadgeSetup',
    components: {
        GeneralSubHeader,
        BadgeImportModal,
        BadgeTypeDescriptions,
    },
    data() {
        return {
            key: 0,
            tabs: [
                {name: 'Administrative Badge', icon: 'flaticon2-layers-1'},
                {name: 'Academic Award', icon: 'flaticon2-writing'},
                // {name: 'Achievement Level', icon: 'flaticon2-line-chart'},
            ],
            administrativeBadges: [],
            achievementBadges: [],
            academicBadges: [],
            otherBadges: [],
            saving: false,
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
        isSetupWizard() {
            return this.$route.name == 'SetupWizardRoute';
        },
        step() {
            return this.$store.state.wizard.steps.find((step) => step.routeName === 'setup-anecdotals');
        },
        selectedTab() {
            return this.$route.query.badgeType || 'Academic Award';
        },
        reloadKey() {
            return this.$store.state.database.reloadKey;
        },
        badges() {
            return this.$store.state.database.badges;
        },
        filteredBadges() {
            if (this.selectedTab === 'Administrative Badge') return this.administrativeBadges;
            if (this.selectedTab === 'Academic Award') return this.academicBadges;
            return this.achievementBadges;
        },
    },
    mounted() {
        const { populate, recalculateStudentBadges } = this;
        populate();

        const { dispatch, commit } = this.$store;
        this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
            dispatch(Types.actions.REFRESH_TABLE_BY_NAME, {
                tableName: 'badges',
                callback: function() {
                    populate();
                    recalculateStudentBadges();
                    commit(Types.mutations.BUMP_RELOAD_KEY);
                }
            });
        });
    },
    methods: {
        skipSetupWizard() {

            this.$store.commit(Types.mutations.SKIP_SETUP_FOR_STUDENT_BADGES, 'true');
        },
        importDefaultSet() {
            this.$refs.badgeImportModal.open();
        },
        recalculateStudentBadges() {
            const { showError, saving } = this;
            if (saving) return;
            this.saving = true;
            const { schoolId, schoolTermId } = this.user.school;
            network.badges.computeBadgesForSchool({ url: { schoolId, schoolTermId } }, (err) => {
                this.saving = false;
                if (err) return showError(err);
            });
        },
        populate() {

            const userId = this.$store.state.user.mappedUserId || this.$store.state.user.userId;

            const badges = [...this.$store.state.database.badges].filter(function(badge) {
                const { badgeManagers } = badge;
                if (badge.deleted) return false;
                if (badgeManagers.length === 0) return true;
                const isManager = badgeManagers.some(function(bm) {
                    return bm.userId == userId;
                });
                return isManager;
            });

            const achievementBadges = [...badges.filter((badge) => badge.badgeType == 'Achievement Level' && !badge.deleted)];
            achievementBadges.sort((a, b) => a.badgeRank - b.badgeRank);
            this.achievementBadges = achievementBadges;

            const administrativeBadges = [...badges.filter((badge) => badge.badgeType == 'Administrative Badge' && !badge.deleted)];
            administrativeBadges.sort((a, b) => a.badgeRank - b.badgeRank);
            this.administrativeBadges = administrativeBadges;

            const academicBadges = [...badges.filter((badge) => badge.badgeType == 'Academic Award' && !badge.deleted)];
            academicBadges.sort((a, b) => a.badgeTitle > b.badgeTitle ? 1 : -1); // lowest to highest
            this.academicBadges = academicBadges;

            const {role} = this.$store.state.user.school;
            if (role !== 'School Admin') {
                this.tabs = this.tabs.filter(tab => tab.name === 'Academic Award');
            }

            this.key += 1;
        },
        moveLevelUp(index) {
            const newIndex = index - 1;
            const { achievementBadges } = this;
            if (newIndex < 0) return;
            arrayMove(achievementBadges, index, newIndex);
            this.saveRanks();
        },
        moveLevelDown(index) {
            const newIndex = index + 1;
            const { achievementBadges } = this;
            if (newIndex >= achievementBadges.length) return;
            arrayMove(achievementBadges, index, newIndex);
            this.saveRanks();
        },
        moveBadgeUp(index) {
            const newIndex = index - 1;
            const { otherBadges } = this;
            if (newIndex < 0) return;
            arrayMove(otherBadges, index, newIndex);
            this.saveRanks();
        },
        moveBadgeDown(index) {
            const newIndex = index + 1;
            const { otherBadges } = this;
            if (newIndex >= otherBadges.length) return;
            arrayMove(otherBadges, index, newIndex);
            this.saveRanks();
        },
        saveRanks() {
            const { showError, $store } = this;
            const badges = [...this.achievementBadges, ...this.otherBadges];

            const params = {
                url: {
                    schoolId: this.user.school.schoolId,
                    schoolTermId: this.user.school.schoolTermId,
                },
                body: {
                    badges: badges.map((badge, idx) => ({ badgeId: badge.badgeId, badgeRank: idx + 1 })),
                },
            };
            network.badges.editRanks(params, (err) => {
                if (err) return showError(err);
                badges.forEach((badge) => {
                    $store.commit(Types.mutations.EDIT_BADGE, { badge });
                });
            });
        },
        createNewCategory() {
            this.$router.push({ name: 'EditAnecdotalCategory', params: { anecdotalEventCategoryId: 'new' } });
        },
        editCategory(category) {
            this.$router.push({ name: 'EditAnecdotalCategory', params: { anecdotalEventCategoryId: category.anecdotalEventCategoryId } });
        },
        deleteBadge(b) {
            const badge = { ...b };
            const { $store, showError, populate } = this;
            const { schoolId, schoolTermId } = this.user.school;
            const { badgeId } = badge;
            if (!window.confirm('Are you sure you want to delete this badge?')) return;
            network.badges.deleteBadge({ url: { schoolId, schoolTermId, badgeId } }, (err) => {
                if (err) return showError(err);
                badge.deleted = true;
                $store.commit(Types.mutations.EDIT_BADGE, { badge });
                populate();
            });
        },
    },
});

function arrayMove(arr, fromIndex, toIndex) {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
}
</script>

<style scoped src='../css/colors.scss' lang="scss" />

<style scoped>
span.kt-point-badge {
    font-size: 1.3rem;
    font-weight: 600;
    margin-left: -12px;
    white-space: nowrap;
}
.table tr td {
    vertical-align: middle;
}
td.row_actions {
    min-width: 100px;
    white-space: nowrap;
}
td.row_desc {
    min-width: 200px;
}
td.row_subs {
    min-width: 200px;
}

.table thead tr td {
    padding-bottom: 20px;
}

a.anchor-link:hover {
    text-decoration: underline !important;
    text-underline-offset: 4px;
}
</style>
