var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("GeneralSubHeader", {
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [_vm._v(" Badge Setup ")]
            },
            proxy: true,
          },
          {
            key: "buttons",
            fn: function () {
              return undefined
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        {
          key: `${_vm.key}_${_vm.reloadKey}`,
          staticClass: "kt-container kt-grid__item kt-grid__item--fluid",
        },
        [
          _c("BadgeTypeDescriptions", {
            attrs: { "badge-type": _vm.selectedTab },
          }),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "kt-portlet kt-portlet--tabs" }, [
                _c("div", { staticClass: "kt-portlet__head" }, [
                  _c("div", { staticClass: "kt-portlet__head-label" }, [
                    _c(
                      "ul",
                      {
                        staticClass:
                          "nav nav-tabs nav-tabs-line nav-tabs-line-brand nav-tabs-line-2x nav-tabs-line-right nav-tabs-bold",
                        attrs: { role: "tablist" },
                      },
                      _vm._l(_vm.tabs, function (tab) {
                        return _c(
                          "li",
                          { key: tab.name, staticClass: "nav-item" },
                          [
                            _c(
                              "router-link",
                              {
                                class:
                                  tab.name == _vm.selectedTab
                                    ? "nav-link active"
                                    : "nav-link",
                                attrs: {
                                  to: {
                                    name: "BadgeSetup",
                                    query: {
                                      badgeType: tab.name,
                                    },
                                  },
                                  role: "tab",
                                },
                              },
                              [
                                _c("i", {
                                  class: tab.icon,
                                  attrs: { "aria-hidden": "true" },
                                }),
                                _vm._v(" " + _vm._s(tab.name) + "s "),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ]),
                  _c("div", { staticClass: "kt-portlet__head-toolbar" }, [
                    _c(
                      "div",
                      { staticClass: "kt-portlet__head-group mt-3" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "btn btn-sm btn-clean",
                            attrs: {
                              to: {
                                name: "EditBadge",
                                query: {
                                  badgeType: _vm.selectedTab,
                                },
                                params: {
                                  badgeId: "new",
                                },
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "la la-plus" }),
                            _vm._v(" Add " + _vm._s(_vm.selectedTab) + " "),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "kt-portlet__body" }, [
                  _c("div", { staticClass: "table-responsive pb-3" }, [
                    _vm.filteredBadges.length == 0
                      ? _c("div", { staticClass: "text-center" }, [
                          _vm.isSetupWizard && _vm.step.status == "danger"
                            ? _c(
                                "a",
                                {
                                  staticClass:
                                    "btn btn-wide btn-upper btn-secondary mt-3 mr-2",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.skipSetupWizard.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "flaticon-download mr-1",
                                  }),
                                  _vm._v(" Skip this step "),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "a",
                            {
                              staticClass:
                                "btn btn-wide btn-upper btn-success mt-3",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.importDefaultSet.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "flaticon-download mr-1",
                              }),
                              _vm._v(" Import Badge Set "),
                            ]
                          ),
                        ])
                      : _c("table", { staticClass: "table table-striped" }, [
                          _c(
                            "tbody",
                            _vm._l(_vm.filteredBadges, function (badge, idx) {
                              return _c(
                                "tr",
                                { key: `badge_${badge.badgeId}` },
                                [
                                  _c("td", { staticClass: "row_desc" }, [
                                    _c("div", { staticClass: "media" }, [
                                      badge.badgeIcon
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "mr-3",
                                              staticStyle: {
                                                "font-size": "2rem",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(badge.badgeIcon) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "media-body align-self-center",
                                        },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              class: `anchor-link kt-font-bold font ${badge.badgeColor}`,
                                              attrs: {
                                                to: {
                                                  name: "EditBadge",
                                                  params: {
                                                    badgeId: badge.badgeId,
                                                  },
                                                },
                                              },
                                            },
                                            [
                                              badge.badgeType ==
                                              "Achievement Level"
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(idx + 1) + ". "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(badge.badgeTitle)
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "text-muted" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    badge.badgeDescription
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]),
                                  _c(
                                    "td",
                                    { staticClass: "row_actions text-center" },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-clean btn-clean-danger btn-icon btn-sm",
                                          attrs: {
                                            "v-b-tooltip": "Delete Badge",
                                            type: "button",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return _vm.deleteBadge(badge)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "la la-trash",
                                          }),
                                        ]
                                      ),
                                      badge.badgeType !== "Academic Award"
                                        ? _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-clean btn-clean-primary btn-icon btn-sm",
                                              attrs: {
                                                "v-b-tooltip": "Move Up",
                                                type: "button",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  return _vm.moveLevelUp(idx)
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "la la-angle-up",
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                      badge.badgeType !== "Academic Award"
                                        ? _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-clean btn-clean-primary btn-icon btn-sm",
                                              attrs: {
                                                "v-b-tooltip": "Move Down",
                                                type: "button",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  return _vm.moveLevelDown(idx)
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "la la-angle-down",
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("BadgeImportModal", { ref: "badgeImportModal" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }