import { render, staticRenderFns } from "./BadgeSetup.vue?vue&type=template&id=99f398f0&scoped=true"
import script from "./BadgeSetup.vue?vue&type=script&lang=ts"
export * from "./BadgeSetup.vue?vue&type=script&lang=ts"
import style0 from "../css/colors.scss?vue&type=style&index=0&id=99f398f0&scoped=true&lang=scss&external"
import style1 from "./BadgeSetup.vue?vue&type=style&index=1&id=99f398f0&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99f398f0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/apps/SyncGrades.Web/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('99f398f0')) {
      api.createRecord('99f398f0', component.options)
    } else {
      api.reload('99f398f0', component.options)
    }
    module.hot.accept("./BadgeSetup.vue?vue&type=template&id=99f398f0&scoped=true", function () {
      api.rerender('99f398f0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/rootComponents/BadgeSetup.vue"
export default component.exports